import { Injectable } from '@angular/core'
import { jwtDecode } from 'jwt-decode'
import { ITokenStructure } from '../app/shared/models/token'
import { IUser } from '../app/shared/models/user'

@Injectable({
	providedIn: 'root',
})
export class EnvironmentService {
	private user: IUser | null = null
	private accessToken: string | null = null

	constructor() {}

	public storeAccessToken(accessToken: string) {
		localStorage.setItem('accessToken', accessToken)
		// this.accessToken = accessToken;
	}

	public storeRefreshToken(refreshToken: string) {
		localStorage.setItem('refreshToken', refreshToken)
	}

	public getRefreshToken() {
		return localStorage.getItem('refreshToken')
	}

	public getUser() {
		if (this.getAccessToken() === null) {
			return null
		}

		const tokenInfo = jwtDecode(this.getAccessToken()!) as ITokenStructure

		// Map token info to IUser
		this.user = {
			id: tokenInfo.user_id,
			name: tokenInfo.name,
			email: tokenInfo.email,
			roles: Array<string>().concat(tokenInfo.roles), // Remove the square brackets
			permissions: [...tokenInfo.permission],
		}

		return this.user
	}

	public getAccessToken() {
		return localStorage.getItem('accessToken')
	}

	public verifyAccessToken() {}

	public getUserId() {
		// Returns the ID of the current user
		return this.user?.id
	}

	public getUserRoles() {
		// Returns the roles of the current user as an array
		return this.user?.roles
	}

	public getUserPermissions() {
		// Returns the permissions of the current user as an array
		return this.user?.permissions
	}

	public checkUserContainsAllPermissions(permissions: string[]) {
		if (this.user === null) {
			this.getUser()
		}
		// Checks if user has every permission
		return this.user
			? permissions.every((p) => this.user?.permissions?.includes(p))
			: false
	}

	public checkUserContainsSomePermissions(permissions: string[]) {
		if (this.user === null) {
			this.getUser()
		}
		// Check if user has the role
		return this.user
			? permissions.some((p) => this.user?.permissions?.includes(p))
			: false
	}

	public checkUserContainsAllRoles(roles: string[]) {
		if (this.user === null) {
			this.getUser()
		}
		// Checks if user has every permission
		return this.user
			? roles.every((r) => this.user?.roles?.includes(r))
			: false
	}

	public checkUserContainsSomeRoles(roles: string[]) {
		if (!roles) return true
		if (this.user === null) {
			this.getUser()
		}
		// Check if user has the role
		return this.user
			? roles.some((r) => this.user?.roles?.includes(r))
			: false
	}

	public getUserProperties() {
		return this.user
	}

	public logout() {
		this.user = null
		localStorage.removeItem('accessToken')
		localStorage.removeItem('refreshToken')
	}

	public isLoggedIn() {
		return this.getAccessToken() !== null
	}
}
