<nav class="flex shadow-3 justify-content-center" *ngIf="!mobile">
	<div id="navbar" class="flex flex-row justify-content-between">
		<!-- Logos -->
		<div id="logos" class="flex">
			<a class="navbar-logo" [routerLink]="homeURL">
				<img
					id="cmcb-logo"
					ngSrc="../../../../../assets/Logo_cb.svg"
					alt="Câmara Municipal de Castelo Branco logo"
					width="200"
					height="50"
				/>
			</a>
			<a class="navbar-logo" [routerLink]="homeURL"
				><img
					id="mobicab-logo"
					ngSrc="../../../../../assets/Mobicab_Logo.png"
					alt="Mobicab logo"
					class="ml-1"
					width="200"
					height="50"
			/></a>
		</div>

		<div class="flex tabs-container">
			<!--TABS -->
			<div class="navbar-links flex gap-2 align-items-end">
				<a
					class="navbar-link"
					[class.current]="activeTab === 'history'"
					(click)="activeTab = 'history'"
					routerLink="/history"
					*ngIf="isLoggedIn()"
				>
					{{ 'pagesTitles.driver-trips' | translate }}
				</a>
				<a
					class="navbar-link"
					[class.current]="activeTab === 'map'"
					(click)="activeTab = 'map'"
					routerLink="/map"
				>
					{{ 'map.map' | translate }}
				</a>
				<a
					class="navbar-link"
					[class.current]="activeTab === 'contacts'"
					(click)="activeTab = 'contacts'"
					routerLink="/contacts"
				>
					{{ 'dashboard.footer.contacts' | translate }}
				</a>
				<button
					pButton
					class="btn btn-primary newRequest"
					(click)="newRequestPage()"
					*ngIf="isLoggedIn()"
				>
					{{ 'requests.newRequest' | translate }}
				</button>
				<a class="login-link" *ngIf="!isLoggedIn()" href="/auth/login">
					{{ 'dashboard.login' | translate }}
				</a>

				<div
					class="flex flex-row h-full align-items-end navbar-user-options"
					*ngIf="isLoggedIn()"
				>
					<!-- NOTIFICATIONS -->
					<div class="navbar-notifications circular-bg navbar-op">
						<a
							class="navbar-notification"
							routerLink="notifications"
						>
							<i
								class="fa-solid fa-bell"
								*ngIf="!newNotification()"
							></i>
							<i
								class="fa-solid fa-bell"
								pBadge
								*ngIf="newNotification()"
							></i>
						</a>
					</div>

					<!-- USER -->
					<p-menu
						#menu
						[model]="items"
						[popup]="true"
						(onShow)="checkLang()"
					>
						<ng-template pTemplate="end">
							<p-dropdown
								[options]="languages"
								[(ngModel)]="selectedLanguage"
								optionLabel="label"
								(onChange)="onLanguageChange()"
								[placeholder]="selectedLanguage.label"
							>
								<ng-template pTemplate="selectedItem">
									<span>
										<img
											[ngSrc]="
												'../../../../../assets/flags/' +
												selectedLanguage.code +
												'.svg'
											"
											style="
												height: 1rem;
												margin-right: 0.5rem;
											"
											width="50"
											height="50"
											alt="Bandeira"
										/>
										<p>{{ selectedLanguage.label }}</p>
									</span>
								</ng-template>
								<ng-template pTemplate="item" let-option>
									<span>
										<img
											[ngSrc]="
												'../../../../../assets/flags/' +
												option.code +
												'.svg'
											"
											style="
												height: 1rem;
												margin-right: 0.5rem;
											"
											width="50"
											height="50"
											alt="Bandeira"
										/>
										<p>{{ option.label }}</p>
									</span>
								</ng-template>
							</p-dropdown>
						</ng-template>
					</p-menu>
					<div
						class="navbar-user circular-bg navbar-op"
						(click)="menu.toggle($event)"
					>
						<a class="navbar-user-link">
							@if (!invalidInitials()) {
								<p id="initials">
									{{ userNameInitials }}
								</p>
							} @else {
								<i class="pi pi-user"></i>
							}
						</a>
					</div>
				</div>

				<div class="changeLanguage" *ngIf="!isLoggedIn()">
					<p-dropdown
						[options]="languages"
						[(ngModel)]="selectedLanguage"
						optionLabel="label"
						(onChange)="onLanguageChange()"
						[placeholder]="selectedLanguage.label"
					>
						<ng-template pTemplate="selectedItem">
							<span>
								<img
									[ngSrc]="
										'../../../../../assets/flags/' +
										selectedLanguage.code +
										'.svg'
									"
									width="50"
									height="50"
									style="height: 1rem; margin-right: 0.5rem"
									alt="Bandeira"
								/>
								<p>{{ selectedLanguage.label }}</p>
							</span>
						</ng-template>
						<ng-template pTemplate="item" let-option>
							<span>
								<img
									[ngSrc]="
										'../../../../../assets/flags/' +
										option.code +
										'.svg'
									"
									style="height: 1rem; margin-right: 0.5rem"
									alt="Bandeira"
									width="50"
									height="50"
								/>
								<p>{{ option.label }}</p>
							</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
		</div>
	</div>
</nav>

<div
	class="flex mobileFooter"
	*ngIf="mobile"
	[class.mobile-menu-guest]="!isLoggedIn()"
>
	<div *ngIf="isLoggedIn()">
		<p-button
			label="{{ 'pagesTitles.driver-trips' | translate }}"
			icon="pi pi-car"
			[text]="true"
			severity="secondary"
			routerLink="/history"
			(onClick)="activeTab = 'history'"
			[class.active-mobile-tab]="activeTab == 'history'"
		></p-button>
	</div>
	<div>
		<p-button
			label="{{ 'map.map' | translate }}"
			icon="pi pi-map"
			[text]="true"
			severity="secondary"
			routerLink="/map"
			(onClick)="activeTab = 'map'"
			[class.active-mobile-tab]="activeTab == 'map'"
		></p-button>
	</div>
	<div>
		<p-button
			label="{{ 'dashboard.footer.contacts' | translate }}"
			icon="pi pi-book"
			[text]="true"
			severity="secondary"
			routerLink="/contacts"
			(onClick)="activeTab = 'contacts'"
			[class.active-mobile-tab]="activeTab == 'contacts'"
		></p-button>
	</div>
	<div>
		<p-menu #menu [model]="items" [popup]="true">
			<ng-template pTemplate="end">
				<p-dropdown
					[options]="languages"
					[(ngModel)]="selectedLanguage"
					optionLabel="label"
					(onChange)="onLanguageChange()"
					[placeholder]="selectedLanguage.label"
				>
					<ng-template pTemplate="selectedItem">
						<span>
							<img
								[ngSrc]="
									'../../../assets/flags/' +
									selectedLanguage.code +
									'.svg'
								"
								style="height: 1rem; margin-right: 0.5rem"
								alt="Bandeira"
								width="50"
								height="50"
							/>
							<p>{{ selectedLanguage.label }}</p>
						</span>
					</ng-template>
					<ng-template pTemplate="item" let-option>
						<span>
							<img
								[ngSrc]="
									'../../../assets/flags/' +
									option.code +
									'.svg'
								"
								style="height: 1rem; margin-right: 0.5rem"
								alt="Bandeira"
								width="50"
								height="50"
							/>
							<p>{{ option.label }}</p>
						</span>
					</ng-template>
				</p-dropdown>
			</ng-template>
		</p-menu>
		<p-button
			label="{{ 'dashboard.profile' | translate }}"
			icon="pi pi-user"
			[text]="true"
			severity="secondary"
			(onClick)="checkLang(); menu.toggle($event)"
			[class.active-mobile-tab]="activeTab == 'profile'"
		></p-button>
	</div>
</div>
