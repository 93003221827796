export enum UserRoleConfig {
	COSTUMER = '46463ca3-498f-4f34-ad67-32dacc198278',
	DRIVER = '9845e5d3-c2b4-42a7-bdd6-0914801ed3a9',
	OPERATOR = '92956741-78ee-4ff3-b8f6-7bb9f74fb672',
	ADMIN = '220a4bbf-f88d-4a5d-9693-9f9f40feb3b3',
}

export enum UserPermissionConfig {
	EditOwnAccount = 'Edit.Own.Account',
	DeleteOwnAccount = 'Delete.Own.Account',

	ReadUser = 'Read.User',
	AddUser = 'Add.User',
	EditUser = 'Edit.User',
	DeleteUser = 'Delete.User',

	ReadVehicle = 'Read.Vehicle',
	AddVehicle = 'Add.Vehicle',
	EditVehicle = 'Edit.Vehicle',
	DeleteVehicle = 'Delete.Vehicle',

	ReadZone = 'Read.Zone',
	AddZone = 'Add.Zone',
	EditZone = 'Edit.Zone',
	DeleteZone = 'Delete.Zone',

	ReadStop = 'Read.Stop',
	AddStop = 'Add.Stop',
	EditStop = 'Edit.Stop',
	DeleteStop = 'Delete.Stop',

	ReadPopulationAggregate = 'Read.Population.Aggregate',
	AddPopulationAggregate = 'Add.Population.Aggregate',
	EditPopulationAggregate = 'Edit.Population.Aggregate',
	DeletePopulationAggregate = 'Delete.Population.Aggregate',

	ViewAllRequests = 'ViewAll.Requests',
	ReadTravelRequest = 'Read.Travel.Request',
	RequestTravel = 'Request.Travel',
	EditTravelRequest = 'Edit.Travel.Request',
	CancelTravelRequest = 'Cancel.Travel.Request',
	DeleteTravelRequest = 'Delete.Travel.Request',

	ApproveRequest = 'Approve.Request',
	DeclineRequest = 'Decline.Request',

	ViewTravelRequestHistoryOwnAccount = 'View.Travel.Request.History.Own.Account',
	ViewTravelRequestHistoryAccount = 'View.Travel.Request.History.Account',
	ViewTripApprovedTravelRequest = 'View.Trip.Approved.Travel.Request',

	ContactCustomer = 'Contact.Customer',

	ReadReports = 'Read.Reports',

	ReadFare = 'Read.Fare',
	EditFare = 'Edit.Fare',
	DeleteFare = 'Delete.Fare',
	CreateFare = 'Create.Fare',

	ReadOperator = 'Read.Operator',
	AddOperator = 'Add.Operator',
	EditOperator = 'Edit.Operator',
	DeleteOperator = 'Delete.Operator',

	CreateTrip = 'Create.Trip',
	EditTrip = 'Edit.Trip',
	DeleteTrip = 'Delete.Trip',
	ReadTrip = 'Read.Trip',

	ReadMonitoring = 'Read.Monitoring',

	ReadPayment = 'Read.Payment',
	CreatePayment = 'Create.Payment',
	EditPayment = 'Edit.Payment',
	DeletePayment = 'Delete.Payment',
}
