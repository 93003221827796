import { Component, Input, signal } from '@angular/core'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { MenuItem } from 'primeng/api'
import { Subject, takeUntil } from 'rxjs'
import { EnvironmentService } from '../../../../../environments/environment.service'

//to mark the new notifications as read
var newNotification = signal(false)
export default newNotification
@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
	@Input() mobile: boolean = false
	private destroy$ = new Subject<void>()
	public selectedLanguage!: { label: string; code: string }
	public languages: { label: string; code: string }[] = [
		{ label: 'Português', code: 'pt-PT' },
		{ label: 'English', code: 'en-UK' },
	]
	items: MenuItem[] | undefined
	public userNameInitials = ''
	public invalidInitials = signal(false)
	public isLoggedIn = signal(false)
	public activeTab: string = ''
	homeURL: string = ''
	constructor(
		private translate: TranslateService,
		private router: Router,
		private apiEnvironmentService: EnvironmentService,
	) { }

	ngOnInit(): void {
		this.getUserNameInitials()
		this.selectedLanguage =
			this.translate.currentLang === 'pt-PT' ||
				this.translate.currentLang === undefined
				? this.languages[0]
				: this.languages[1]
		this.translate.onLangChange.subscribe(() => {
			this.selectedLanguage = this.languages.find(
				(lan) => lan.code === this.translate.currentLang,
			)!
		})
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
			this.activeTab = this.router.url.split('/')[1]
		})
		this.checkLang()
	}

	newNotification() {
		return newNotification()
	}

	//checks for changes in the language of the menu
	checkLang() {
		this.translate
			.get('navBar')
			.pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
				if (!this.isLoggedIn())
					this.items = [
						{
							label: res.login,
							icon: 'pi pi-sign-in',
							command: () => {
								this.router.navigate(['/auth/login'])
							},
						},
					]
				else
					this.items = [
						{
							label: res.settings,
							icon: 'pi pi-cog',
							command: () => {
								this.router.navigate(['/profile'])
							},
						},
						{
							label: res.logout,
							icon: 'pi pi-sign-out',
							command: () => {
								this.logout()
							},
						},
					]
			})
	}

	// Get username initials in uppercase
	getUserNameInitials() {
		this.userNameInitials = this.apiEnvironmentService
			.getUser()
			?.name.split(' ')
			.map((n: string) => n[0])
			.join('')
			.toLocaleUpperCase()!
		if (this.userNameInitials === undefined) this.isLoggedIn.set(false)
		else this.isLoggedIn.set(true)

		if (
			this.userNameInitials == undefined ||
			this.userNameInitials.length <= 0 ||
			this.userNameInitials.length > 2
		) {
			this.invalidInitials.set(true)
		}

		return this.userNameInitials
	}

	onLanguageChange() {
		this.translate.use(this.selectedLanguage.code)
		this.checkLang() //to refresh the menu
		localStorage.setItem('language', this.selectedLanguage.code)
	}

	logout() {
		this.apiEnvironmentService.logout()
		this.router.navigate(['/auth/login'])
	}

	ngOnDestroy() {
		// Emit a value to destroy the subscription
		this.destroy$.next()
		// Complete the subject to release resources
		this.destroy$.complete()
	}

	newRequestPage() {
		this.router
			.navigateByUrl('/refresh', { skipLocationChange: true })
			.then(() => {
				this.router.navigate(['/requests'])
			})
	}
}
